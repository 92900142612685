import React from "react";
import ReactGA from "react-ga";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

import { getActiveTheme } from "utils/themes";

import Layout from "components/Layout";
import Content from "components/Content";
import SEO from "components/Seo";
import GridTemplate from "components/GridTemplate";

const trackLabsClick = () => {
  ReactGA.event({
    category: "404",
    action: "click",
    label: `404 - Go to labs`,
  });
};

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page not found" />
      <GridTemplate>
        <Content>
          <img src={require("/content/assets/hotcoffeepot.png")} alt="Eminem" />
          <h1>
            404
            {/* <span className='small' role='img' aria-label='Scared emoji'>
              😱
            </span> */}
          </h1>

          <p className="support-title">
            {/* But don't worry! */}
            {/* <span className='small' role='img' aria-label='Happy emoji'>
              😄
            </span> */}
          </p>

          <p>
            {" "}
            <Link to="/21m/">click here</Link>!{" "}
            {/* <span
              className="small"
              role="img"
              aria-label="Passionate eyes emoji"
            >
              {/* 😍 */}
            {/* </span> */}
          </p>
        </Content>
      </GridTemplate>
    </Layout>
  );
};

export default NotFoundPage;
